import {
    BooleanField,
    Datagrid, DateField,
    List,
    NumberField,
    TextField,
    useRecordContext
} from 'react-admin';
import {Box, Button, IconButton, Typography} from "@mui/material";
import MaterialTextField from '@mui/material/TextField';
import Grid from "@mui/system/Unstable_Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import React, {useState} from "react";

interface GatewayKey {
    id: number,
    validFrom: string,
    validUntil: string,
    value: string,
    revoked: boolean,
    registered: boolean,
}

interface SecurityEntry {
    id: string,
    registeredKeyCount: number,
    revokedKeyCount: number,
    keyCount: number,
    keys: Array<GatewayKey>
}

const DynamicEditField = ({value, isEdit, setIsEdit, sx, ...props}) => {
    const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        setIsEdit(!isEdit);
    };
    return (
        <Box sx={sx}>
            {!isEdit && (<Typography onClick={handleClick} {...props} >
                {value}
            </Typography>)}
            {isEdit && (<MaterialTextField label={"Gateway Key"} sx={{width: '100%'}} value={value} variant="filled" />)}
        </Box>
    );
}

const SecurityDetailPanel = () => {
    const record: SecurityEntry = useRecordContext();

    const authKeys: Array<GatewayKey> = record.keys;
    const [inputStates, setInputStates] = useState(authKeys.map((value, index) => false));

    const setInputState = (idx) => {
        if (inputStates.length < idx)
            return (_) => {console.log(`setInputState called with invalid index ${idx}`)};

        return (value) => {
            let newStates = [...inputStates];
            newStates[idx] = value;
            setInputStates(newStates);
        }
    };

    const deleteKey = (gateway, keyId) => {

    };

    return (
        <Grid  container sx={{flexGrow: 1}}>
            <Grid xs={12} >
                <Typography variant="h6" >Edit keys</Typography>
                <Typography variant="body2">
                    The gateway may use the keys given below to authenticate itself with the CoAP-server. To edit a key, simply click the field to be edited. After editing, click the respective save button of the edited key to save the changes.
                </Typography>
            </Grid>
            {authKeys.map((key, idx, array) => {
                return (
                    <Grid  key={key.id} columnSpacing={1} container sx={{marginLeft: 5}} xs={12}>
                        {/*<Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} xs={5}>*/}
                        {/*        /!*<DynamicEditField isEdit={inputStates[idx]} setIsEdit={setInputState(idx)} value={key.value} sx={{flexShrink: 1, overflow: 'hidden'}} variant="body2" component="span" />*!/*/}
                        {/*</Grid>*/}
                        <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} xs={2} >
                            <Typography variant="body2" component="span" >
                                {key.validFrom}
                            </Typography>
                        </Grid>
                        <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} xs={2} >
                            <Typography variant="body2" component="span" >
                                {key.validUntil}
                            </Typography>
                        </Grid>
                        <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} xs={1} >
                            <Typography variant="body2" component="span" >
                                {key.revoked ? "Yes" : "No"}
                            </Typography>
                        </Grid>
                        <Grid sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} xs={1} >
                            <Typography variant="body2" component="span" >
                                {key.registered ? "Yes" : "No"}
                            </Typography>
                        </Grid>
                        <Grid xs={6} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}} >
                            <IconButton onClick={(_) => deleteKey(record.id, key.id)}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton>
                                <SaveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                )
            })}
            <Grid xsOffset={11} xs={1} sx={{display: 'flex', justifyContent: 'flex-end'}} >
                <Button variant='contained'>Add Key</Button>
            </Grid>
        </Grid>
    );
}

export const SecurityList = () => {
    return (
        <List>
            <Datagrid
                rowClick="edit"
                expand={<SecurityDetailPanel />}
            >
                <TextField label={"Barcode"} source="barcode" />
                <TextField label={"UUID"} source="id" />
                <NumberField label={"# Registered Keys"} source="registeredKeyCount" />
                <NumberField label={"# Revoked Keys"} source="revokedKeyCount" />
                <NumberField label={"# Keys"} source="keyCount" />
            </Datagrid>
        </List>
    );
};