import {Box, Tabs, Tab, Typography} from "@mui/material";
import {ReactNode, SyntheticEvent, useEffect, useState} from "react";
import {ResourceContextProvider, useAuthenticated, useRedirect} from "react-admin";
import {GatewayList} from "./GatewayList";
import {GatewayDevicesList} from "./GatewayDevicesList";
import {matchPath, useLocation, useNavigate} from "react-router-dom";

interface TabPanelProps {
    children?: ReactNode;
    index: string;
    value: string;
}
const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vattenfall-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3 }} >
                    {children}
                </Box>
            )}
        </div>
    )
}
const tabs = ["gateways", "gatewaydevices"];
export const Assets = () => {
    useAuthenticated();
    const location = useLocation();
    const redirect = useRedirect();
    let match = matchPath("/assets/:view", location.pathname);
    if (!match)
        match = matchPath("assets/:view/:action", location.pathname);

    const sanitisedMatch = (match) => {
        if (match && tabs.includes(match.params.view))
            return match.params.view;

        return tabs[0];
    }

    const [tabValue, setTabValue] = useState(sanitisedMatch(match));

    useEffect(() => {
        if (!match || !tabs.includes(match.params.view))
            redirect('/assets/gateways');
    }, [match, redirect]);

    const handleChange = (event: SyntheticEvent, val: string) => {
      setTabValue(val);
      redirect(`/assets/${val}`);
    };


    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab value="gateways" label="Gateways"  />
                    <Tab value="gatewaydevices" label="Gateway Devices"  />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={"gateways"}>
                <ResourceContextProvider value="gateways" >
                    <GatewayList />
                </ResourceContextProvider>
            </TabPanel>
            <TabPanel value={tabValue} index={"gatewaydevices"}>
                <ResourceContextProvider value="gatewaydevices" >
                    <GatewayDevicesList />
                </ResourceContextProvider>
            </TabPanel>
        </>
    )
};