import {Card, CardHeader, CardContent, MenuItem} from '@mui/material';
import {
    Pie,
    PieChart,
    Legend,
    Cell,
    ResponsiveContainer
} from "recharts";
import {Suspense, useState} from "react";
import Select, {SelectChangeEvent} from '@mui/material/Select';
import {DataPieChart} from "../../components/DataPieChart";
import {DataBarChart} from "../../components/DataBarChart";
import {ErrorBoundary} from "react-error-boundary";
import {GridLoader} from "react-spinners";
import {useAuthenticated} from "react-admin";

const validEventFilters = ["hour", "day", "week", "month", "year", "historic"];

export const DashboardPage = () => {
    useAuthenticated();
    const [eventTimeFilter, setEventTimeFilter] = useState(validEventFilters[1]);
    const [eventUrl, setEventUrl] = useState(`/eventsdata?time=${validEventFilters[1]}`)
    const handleEventTimeFilterChange = (event: SelectChangeEvent) => {
        const val = event.target.value;
        setEventUrl(`/eventsdata?time=${val}`);
        setEventTimeFilter(val);
    };

    const data02 = [
        { name: "Data allowed ON", value: 12405 },
        { name: "Data allowed OFF", value: 20482 }
    ];

    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
    const COLORS2 = ["#1B5583", "#F3A505"]

    const CustomizedLegend = (props) => {
        const { payload } = props;
        return (
            <ul className="LegendList">
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{listStyle: "none"}}>
                        <div className="BulletLabel" style={{display: "flex", alignItems: "center", gap: "10px", width: "100%"}}>
                            <Bullet backgroundColor={entry.payload.fill} size="10px" />
                            <div className="BulletLabelText">{entry.value}</div>
                        </div>
                        <div style={{ marginLeft: "20px" }}>{entry.payload.value}</div>
                    </li>
                ))}
            </ul>
        );
    };

    const Bullet = ({ backgroundColor, size }) => {
        return (
            <div
                style={{
                    backgroundColor,
                    width: size,
                    height: size
                }}
            ></div>
        );
    };

    return (
        <>
            <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
                <Card style={{marginTop: "10px", width: "20%"}}>
                    <CardHeader title={"Gateway Status (past day)"} />
                    <CardContent>
                        <div style={{ width: "100%", height: 420 }}>
                            <ErrorBoundary fallback={<h2>Could not load data</h2>} >
                                    <DataPieChart
                                        url={"/gatewaysonline?lastAlive=day"}
                                        chartName="gatewayOnlineDay"
                                        colors={COLORS}
                                    />
                            </ErrorBoundary>
                        </div>
                    </CardContent>
                </Card>
                <Card style={{marginTop: "10px", width: "20%"}}>
                    <CardHeader title={"Gateway Status (past hour)"} />
                    <CardContent>
                        <div style={{ width: "100%", height: 420 }}>
                            <ErrorBoundary fallback={<h2>Could not load data</h2>} >
                                    <DataPieChart
                                        url={"/gatewaysonline?lastAlive=hour"}
                                        chartName="gatewayOnlineHour"
                                        colors={COLORS}
                                    />
                            </ErrorBoundary>
                        </div>
                    </CardContent>
                </Card>
                <Card style={{marginTop: "10px", width: "20%"}}>
                    <CardHeader title={"Events"} />
                    <CardContent>
                        <div style={{width: "100%", height: 420}} >
                            <ErrorBoundary fallback={<h2>Could not load data</h2>} >
                                    <DataPieChart
                                        chartName="eventChart"
                                        url={eventUrl}
                                        colors={COLORS}
                                    />
                            </ErrorBoundary>
                        </div>
                        <Select
                            sx={{
                                display: "flex",
                                flex: 1,
                                margin: "8px",
                                '& .MuiSelectLabel': {
                                    color: 'black'
                                }
                            }}
                            label="Timespan"
                            onChange={handleEventTimeFilterChange}
                            value={eventTimeFilter}
                        >
                            <MenuItem value={validEventFilters[0]}>Past hour</MenuItem>
                            <MenuItem value={validEventFilters[1]}>Past day</MenuItem>
                            <MenuItem value={validEventFilters[2]}>Past week</MenuItem>
                            <MenuItem value={validEventFilters[3]}>Past month</MenuItem>
                            <MenuItem value={validEventFilters[4]}>Past year</MenuItem>
                            <MenuItem value={validEventFilters[5]}>All</MenuItem>
                        </Select>
                    </CardContent>
                </Card>
                <Card style={{marginTop: "10px", width: "20%"}}>
                    <CardHeader title={"mBUS Status"} />
                    <CardContent>
                        <div style={{ width: "100%", height: 420 }}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={data02}
                                        dataKey="value"
                                        cx={110}
                                        cy={150}
                                    >
                                        {data02.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={COLORS2[index % COLORS2.length]}
                                            />
                                        ))}
                                    </Pie>
                                    <Legend content={<CustomizedLegend />} />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
                {/*<DashboardBlogs />*/}
            </div>
        </>
    );
}