import { Datagrid, List, TextField } from 'react-admin';
import {FilterSidebar} from "../../components/FilterSidebar";

export const NetworkList = () => (
    <List
        aside={<FilterSidebar
            filterFields={[
                {filterName: "networkMode", filterValues: [{networkMode: "nb-iot"}, {networkMode: "wifi"}]}
            ]}
        />}
    >
        <Datagrid rowClick="edit">
            <TextField label={"ICC_ID"} source="ICC_ID" />
            <TextField label={"IMEI"} source="IMEI" />
            <TextField label={"Network Mode"} source="networkMode" />
        </Datagrid>
    </List>
);