import {Datagrid, DateField, List, ReferenceField, ReferenceOneField, TextField} from 'react-admin';

export const GatewayDevicesList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField label={"Secondary Address"} source="secondaryAddress" />
            <DateField label={"First Seen"} source="firstSeen" />
            <DateField label={"Last Seen"} source="lastSeen" />
            {/*<ReferenceOneField link="show" label={"Connected to Gateway"} reference="gatewaystatuses" target="gatewayUuid" emptyText="Not connected">*/}
            {/*    <TextField source="gatewayUuid" />*/}
            {/*</ReferenceOneField>*/}
            <TextField source="gatewayUuid" emptyText="Not connected" />
            <TextField label={"Connected Since"} source="connectedSince" />
        </Datagrid>
    </List>
);