import {appHttpClient} from "../App";

export type ChartData = Array<{name: string, value: number}>;
export type ChartDataError = ChartData | string;

export const fetchChartData = (url, setData, setState, cache = null) => {
    let fetch = appHttpClient(url).then((value) => {
        if (value.status !== 200) {
            setState(s => "rejected");
            setData(d => `Server returned a ${value.status} code`);
        }
        setState(s => "fulfilled");
        if (cache !== null && cache !== false && typeof(cache) === 'string') {
            localStorage.setItem(cache, JSON.stringify({
                cacheAge: Date.now(),
                cacheData: value.json
            }));
        }
        setData(d => value.json);
    }).catch((reason) => {
        setState(s => "rejected");
        setData(d => `Data fetch error: ${reason}`);
    });
};

export default fetchChartData;