import {
    Datagrid,
    ListContextProvider, ListView,
    NumberField,
    TextField,
    useListController
} from 'react-admin';
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import React, {useCallback} from "react";
import {Drawer, Grid, Typography} from "@mui/material";
import GatewayEdit from "./GatewayEdit";
import {ChartData} from "../../components/fetchChartData";
import {DataPieChart} from "../../components/DataPieChart";

export const GatewayList = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const listContext = useListController();
    let match = matchPath('/assets/gateways/:id', location.pathname);

    if (!match)
        match = matchPath('/assets/gateways/:id/show', location.pathname);


    const handleClose = useCallback(() => {
        navigate('/assets/gateways');
    }, [navigate]);

    const gatewayTypes: ChartData = [
        {name: "HLD", value: 0},
        {name: "WLD", value: 0}
    ];

    if (!listContext.isLoading) {
        let HLD = listContext.data.reduce((previousValue, currentValue) => previousValue += currentValue["type"]);
        gatewayTypes[0].value = HLD;
        gatewayTypes[1].value = listContext.data.length - HLD;
    }


    const COLORS2 = ["#1B5583", "#F3A505"];

    const GatewayRowClick = (id, resource, record) => `/assets/gateways/${id}`;

    return (
        <Grid container>
            <Grid item maxHeight={"350px"} xs={4}>
                <Typography>Types</Typography>
                <DataPieChart
                    url={'/gatewaychart?dataField=type'}
                    noLegend
                    showTooltip
                    showLabel
                    chartName='gatewayTypeChart'
                    colors={COLORS2}
                />
            </Grid>
            <Grid item xs={4} maxHeight={"350px"}>
                <Typography>Firmwares</Typography>
                <DataPieChart
                    url={'/gatewaychart?dataField=firmware'}
                    noLegend
                    showTooltip
                    showLabel
                    chartName='gatewayFirmwareChart'
                    colors={COLORS2}
                />
            </Grid>
            <Grid item xs={4} maxHeight={"350px"}>
                <Typography>chart 3</Typography>
                <DataPieChart
                    url={'/gatewaychart?dataField=network'}
                    noLegend
                    showTooltip
                    showLabel
                    chartName='gatewayNetworkChart'
                    colors={COLORS2}
                />

            </Grid>
            <Grid item xs={12}>
                <ListContextProvider value={listContext} >
                    <ListView>
                        <Datagrid rowClick={GatewayRowClick}>
                            <TextField source="id"/>
                            <TextField source="barcode"/>
                            <TextField source="type"/>
                            <NumberField source="deviceCount"/>
                            <TextField source="lastAlive"/>
                            <TextField source="firmwareVersion"/>
                        </Datagrid>
                        <Drawer
                            variant='persistent'
                            open={!!match}
                            anchor={'right'}
                            onClose={handleClose}
                        >
                            {!!match && (<GatewayEdit id={(match as any).params.id} onCancel={handleClose}/>)}
                        </Drawer>

                    </ListView>

                </ListContextProvider>
            </Grid>
        </Grid>

    )
};