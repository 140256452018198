import {Grid, Typography} from "@mui/material";
import {DataPieChart} from "../../components/DataPieChart";

export const AlarmEventsDashboard = () => (
    <Grid container xs={12} >
        <Grid item xs={12} >
            <DataPieChart
                chartName="eventChart"
                url='/eventsdata'
                colors={["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]}
            />
        </Grid>
    </Grid>
)