import {List} from "react-admin";
import {Drawer} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useCallback} from "react";

export const DrawerDataList = ({children, drawer, match}) => {
    const navigate = useNavigate();
    const handleClose = useCallback(() => {
        navigate("../");
    }, [navigate]);

    return (
        <>
            <List>
                {children}
            </List>
            <Drawer
                variant='persistent'
                open={!!match}
                anchor={'right'}
                onClose={handleClose}
            >
                {match && (drawer)}
            </Drawer>
        </>
    )
};