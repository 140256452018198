import {Cell, LabelList, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import fetchChartData, {ChartData, ChartDataError} from "./fetchChartData";
import {useEffect, useState} from "react";
import {GridLoader} from "react-spinners";
import {Typography} from "@mui/material";

const fetchCacheData = (chartName, setStatus, setChartData) => {
    if (chartName === null || typeof(chartName) !== 'string')
        return;

    // Check cache for pre-fetched data
    const cacheStr = localStorage.getItem(chartName);
    if (cacheStr === null)
        return;

    setStatus('pending');
    const cacheObj = JSON.parse(cacheStr);
    let cacheAge: number|null = null;
    // Validate cacheData
    if (cacheObj.hasOwnProperty('cacheAge'))
        cacheAge = cacheObj['cacheAge'];

    if (cacheObj.hasOwnProperty('cacheData'))
        setChartData(cacheObj['cacheData'] as ChartData);

    const test = cacheAge !== null ? cacheAge + 5 * 60 * 1000 : 0;
    if (cacheAge === null || (cacheAge + 5 * 60 * 1000 ) < Date.now()) {
        console.log(`Cache for ${chartName} invalidated, reloading`);
        setStatus('reload');
    }

    console.log(`Fetched data for ${chartName} from cache.`);
}

export const DataPieChart = ({url, chartName = null, colors, noLegend = false, showTooltip = false, showLabel = false}) => {
    const [chartData, setChartData] = useState<ChartDataError>([]);
    const [status, setStatus] = useState('initial');
    const [lastUrl, setLastUrl] = useState('');

    useEffect(() => {
        if (status === 'initial' ) {
            fetchCacheData(chartName, setStatus, setChartData);
        }
        if (lastUrl !== url || status === 'reload') {
            setStatus("pending");
            setLastUrl(url);
            fetchChartData(url, setChartData, setStatus, typeof(chartName) === 'string' ? chartName : null);
        }
    });

    const Bullet = ({ backgroundColor, size }) => {
        return (
            <div
                style={{
                    backgroundColor,
                    width: size,
                    height: size
                }}
            ></div>
        );
    };
    const renderCustomizedLabel = (entry) => {
        return entry.name;
    };
    const CustomizedLegend = (props) => {
        const { payload } = props;
        return (
            <ul className="LegendList">
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{listStyle: "none"}}>
                        <div className="BulletLabel" style={{display: "flex", alignItems: "center", gap: "10px", width: "100%"}}>
                            <Bullet backgroundColor={entry.payload.fill} size="10px" />
                            <div className="BulletLabelText">{entry.value}</div>
                        </div>
                        <div style={{ marginLeft: "20px" }}>{entry.payload.value}</div>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <>
            {(status === 'initial') && <GridLoader />}
            {status === 'rejected' && <Typography>Cannot load data: {chartData as string}</Typography>}
            {(status === 'fulfilled' || status === 'pending') &&
                <ResponsiveContainer>
                    <PieChart>
                        {status === 'pending' && <GridLoader />}
                        <Pie
                            data={chartData as ChartData}
                            dataKey="value"
                            nameKey="name"
                            label={showLabel ? renderCustomizedLabel : false}
                        >
                            {(chartData as ChartData).map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colors[index % colors.length]}
                                />
                            ))}
                        </Pie>
                        {showTooltip && <Tooltip />}
                        {!noLegend && <Legend content={<CustomizedLegend />} />}
                    </PieChart>
                </ResponsiveContainer>}
        </>

    )
}