import {DateField, EditBase, EditProps, Labeled, NumberField, SimpleForm, TextField} from "react-admin";
import React from "react";
import {Box, Grid, Stack, Typography, IconButton} from '@mui/material';
import { Close } from '@mui/icons-material'

interface Props extends EditProps {
    onCancel: () => void
}

const GatewayEdit = ({onCancel, ...props}: Props) => {
    return (
        <EditBase {...props}>
            <Box pt={5} width={{ xs: '100vw', sm: 500 }} mt={{ xs: 2, sm: 1 }}>
                <Stack direction="row" p={2}>
                    <Typography variant="h6" flex="1">
                        Gateway
                    </Typography>
                    <IconButton onClick={onCancel} size="small">
                        <Close />
                    </IconButton>
                </Stack>
                <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={null}>
                    <TextField source="id"/>
                    <TextField source="barcode"/>
                    <TextField source="type"/>
                    <NumberField source="deviceCount"/>
                    <TextField source="lastAlive"/>
                    <TextField source="firmwareVersion"/>
                </SimpleForm>
            </Box>
        </EditBase>
    );
};

export default GatewayEdit;