import {TabbedResourcePage} from "../../components/TabbedResourcePage";
import {EventList} from "./EventList";
import {AlarmEventsDashboard} from "./AlarmEventsDashboard";
import {matchPath, useLocation} from "react-router-dom";

export const AlarmEventsPage = () => {
    const location = useLocation();
    const pageName = "alarmsevents";

    const EventRowClick = (id, record, resource) => `/${pageName}/events/${id}`;
    const match = matchPath(`${pageName}/events/:id`, location.pathname);

    const tabs = [
        ['dashboard', "Dashboard", (<AlarmEventsDashboard />)],
        ['events', "Events", (<EventList rowEdit={EventRowClick} match={match} />)]
    ]
    return (
        <TabbedResourcePage
            name={pageName}
            tabs={tabs}
        />)
}