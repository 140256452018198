import { Datagrid, DateField, List, NumberField, TextField } from 'react-admin';
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {Drawer} from "@mui/material";
import {DataRequestEdit} from "./DataRequestEdit";
import {useCallback} from "react";

export const DataRequestList = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
       navigate("/datarequests")
    }, [navigate]);

    const match = matchPath("/datarequests/:id", location.pathname);

    return (
    <List>
        <Datagrid rowClick="edit">
            <TextField label={"UUID"} source="id"/>
            <TextField source="status" />
            <DateField label={"Start Datetime"} source="startDateTime"/>
            <DateField label={"End Datetime"} source="endDateTime"/>
            <TextField label={"Aggregation"} source="aggregation"/>
            <TextField label={"Delay (ms)"} source="delay"/>
            <TextField label={"Reference ID"} source="refIdentifier"/>
            <TextField label={"Callback URL"} source="callbackURL"/>
            <NumberField label={"Wait for Data (ms)"} source="waitForData"/>
        </Datagrid>
        <Drawer
            open={!!match}
            anchor={"right"}
            variant="persistent"
            onClose={handleClose}
        >
            {!!match && (<DataRequestEdit onCancel={handleClose} id={(match as any).params.id} />)}
        </Drawer>
    </List>
)};