import {
    ArrayField,
    Datagrid, DateField,
    EditBase,
    EditProps, Labeled,
    SimpleForm,
    TextField,
} from 'react-admin';
import {Box, Grid, IconButton, Stack, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import React from "react";


interface DataRequestEditProps extends EditProps {
    onCancel: () => void
}
export const DataRequestEdit = ({onCancel, ...props}: DataRequestEditProps) => (
    <EditBase {...props}>
        <Box pt={5} width={{ xs: '100vw', sm: 750 }} mt={{ xs: 2, sm: 1 }}>
            <Stack direction="row" p={2}>
                <Typography variant="h6" flex="1">
                    Data Request Details
                </Typography>
                <IconButton onClick={onCancel} size="small">
                    <Close />
                </IconButton>
            </Stack>
            <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={null}>
                <Grid container rowSpacing={1} >
                    <Grid item xs={10} >
                        <Labeled>
                            <TextField source="id" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={2} >
                        <Labeled>
                            <TextField label="Wait for Data" source="waitForData" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3} >
                        <Labeled>
                            <DateField label="Start Date" source="startDateTime" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3} >
                        <Labeled>
                            <DateField label="End Date" source="endDateTime" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3} >
                        <Labeled>
                            <TextField source="aggregation" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={3} >
                        <Labeled>
                            <TextField source="delay" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} >
                        <Labeled>
                            <TextField label="Reference Identifier" source="refIdentifier" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} >
                        <Labeled>
                            <TextField label="Callback URL" source="callbackURL" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography flex="1" >
                            Data Check Status
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <ArrayField source="dataChecks">
                            <Datagrid bulkActionButtons={false} >
                                <TextField label="Execute At" source="checkDatetime" />
                                <TextField source="status" />
                                <TextField source="dataCount" />
                                <TextField source="expectedDataCount" />
                            </Datagrid>
                        </ArrayField>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography flex="1">
                            Requested Devices & Measuring Types
                        </Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <ArrayField source="devices" >
                            <Datagrid bulkActionButtons={false} >
                                <TextField source="deviceIdentifier" />
                                <ArrayField source="measuringTypes" >
                                    <Datagrid bulkActionButtons={false}>
                                        <TextField source="type" />
                                        <TextField source="unit" />
                                    </Datagrid>
                                </ArrayField>
                            </Datagrid>
                        </ArrayField>
                    </Grid>
                </Grid>

                {/*<ArrayInput source="devices"><SimpleFormIterator><TextInput source="deviceIdentifier" />*/}
                {/*    <ArrayInput source="measuringTypes"><SimpleFormIterator><ReferenceInput source="measuringTypeId" reference="measuringTypes" />*/}
                {/*        <TextInput source="type" />*/}
                {/*        <TextInput source="unit" />*/}
                {/*        <NumberInput source="exp" /></SimpleFormIterator></ArrayInput></SimpleFormIterator></ArrayInput>*/}

            </SimpleForm>
        </Box>
    </EditBase>
);