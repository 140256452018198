import {
    FilterLiveSearch,
    FilterList,
    FilterListItem
} from 'react-admin';
import { Card, CardProps, CardContent } from '@mui/material';
import {ImportContacts} from "@mui/icons-material";

interface FilterField {
    filterName: string,
    filterLabel?: string,
    filterValues: any[]
}
interface FilterSidebarProps extends CardProps {
    filterFields: [FilterField]
}

export const FilterSidebar = ({filterFields}: FilterSidebarProps) => {
    return (<Card sx={{order: -1, mr: 2, mt: 8, width: 200}}>
        <CardContent>
            <FilterLiveSearch source={'search'}/>
            {filterFields.map((value) => (
                <FilterList label={value.filterLabel ?? value.filterName} icon={<ImportContacts />} >
                    {value.filterValues.map((filterValue) => (
                        <FilterListItem
                            label={filterValue[Object.getOwnPropertyNames(filterValue)[0]]}
                            value={filterValue}
                        />
                    ))}
                </FilterList>
            ))}
        </CardContent>
    </Card>)
};