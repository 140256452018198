import {TabbedResourcePage} from "../../components/TabbedResourcePage";
import {CommunicationDashboard} from "./CommunicationDashboard";
import {NetworkList} from "./NetworkList";

export const CommunicationPage = () => {
    const tabs = [
        ['dashboard', "Dashboard", (<CommunicationDashboard />)],
        ['network', "Network Management", (<NetworkList />)]
    ]
    return (
        <TabbedResourcePage
            name={"communication"}
            tabs={tabs}
        />)
}