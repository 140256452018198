// in src/MyMenu.js
import { Menu } from 'react-admin';
import {AccountTree} from "@mui/icons-material";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CellTowerIcon from '@mui/icons-material/CellTower';

export const VattenfallMenu = () => (
    <Menu>
        <Menu.DashboardItem to={"/"}/>
        <Menu.Item leftIcon={<AccountTree/>} primaryText={"Assets"} to={"/assets"}/>
        <Menu.Item leftIcon={<NotificationsActiveIcon />} primaryText={"Alarms & Events"} to={"/alarmsevents"} />
        <Menu.Item leftIcon={<CellTowerIcon />} primaryText={"Network & Communication"} to={"/communication"} />
        <Menu.ResourceItem name={"datarequests"} />
        <Menu.ResourceItem name={"security"} />
    </Menu>
);