import {AppBar, Layout, MenuItemLink, useLogout} from "react-admin";
import {VattenfallMenu} from "../components/VattenfallMenu";
import {UserMenu} from "react-admin";
import {Box, MenuItem} from '@mui/material';
import {createElement} from "react";

// @ts-ignore
import logo from "../resources/logo_appbar.png";


export const VattenfallLayout = (props) => {
    return (
        <Layout {...props} menu={VattenfallMenu} appBar={VattenfallAppBar}/>
    )
}

const VattenfallAppBar = () => {
    const logout = useLogout();

    return (<AppBar userMenu={<UserMenu>
                <MenuItem onClick={() => logout()}>Sign out</MenuItem>
            </UserMenu>}>
            <Box component="span" flex="1" />
            <Box component="div" display={"flex"} justifyContent={"center"} maxHeight="100px" alignItems={"center"} alignContent={"center"} flex="1">
                <img height="48px" src={logo} />
            </Box>
            <Box component="span" flex="1" />
    </AppBar>
    )
}