import React from 'react';
import './App.css';
import {Admin, Resource, CustomRoutes} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { Route } from 'react-router-dom';
import {fetchUtils} from "ra-core";
import {StorageKey} from "./AppStorage";
import {authProvider, refreshTokens} from "./auth/authProvider";
import {VattenfallLayout} from "./layout/VattenfallLayout";
import {DashboardPage} from "./pages/dashboard/DashboardPage";
import {LoginPage} from "./pages/login/LoginPage";
import {DataRequestList} from "./datarequests/DataRequestList";
import {Assets} from "./pages/assets/Assets";
import {SecurityList} from "./security/SecurityList";
import {AlarmEventsPage} from "./pages/event/AlarmEventsPage";
import {CommunicationPage} from "./pages/communication/CommunicationPage";

export const appHttpClient = (url, options: fetchUtils.Options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json',}) as Headers;
    }

    let finalUrl = url;

    try {
        new URL(finalUrl);
    } catch (e: unknown) {
        if (e instanceof TypeError)
            finalUrl = appApiUrl + url;
        else
            return Promise.reject(e)
    }

    return refreshTokens()
        .then(() => {
            const token = localStorage.getItem(StorageKey.TOKEN);
            (options.headers as Headers).set('Authorization', `Bearer ${token}`)

            return fetchUtils.fetchJson(finalUrl, options)})
        .catch((reason) => {
            return Promise.reject(reason);
        })
};
export const appApiUrl = process.env.REACT_APP_API_URL ?? 'https://hes-dms.dev.zks.vfrm.io/api/internal/admin';
export const appDataProvider = jsonServerProvider('', appHttpClient);
export const App = () => {
    return (<Admin
        authProvider={authProvider}
        layout={VattenfallLayout}
        dataProvider={appDataProvider}
        loginPage={LoginPage}
        requireAuth
    >
        <CustomRoutes>
            <Route path="/" element={<DashboardPage/>} />
            <Route path="/assets" element={<Assets/>} >
                <Route path="/assets/:view" element={<Assets/>} >
                    <Route path="/assets/:view/:id" element={<Assets />} />
                </Route>
            </Route>
            <Route path="/alarmsevents" element={<AlarmEventsPage />} >
                <Route path="/alarmsevents/:view" element={<AlarmEventsPage />} >
                    <Route path="/alarmsevents/:view/:id" element={<Assets />} />
                </Route>
            </Route>
            <Route path="/communication" element={<CommunicationPage />} >
                <Route path="/communication/:view" element={<CommunicationPage />} />
            </Route>
        </CustomRoutes>
        <Resource options={{label: "Data Requests"}} name={"datarequests"} list={DataRequestList} />
        <Resource options={{label: "Security"}} name={"security"} list={SecurityList} />
    </Admin>)
}
