import {StorageKey} from "../AppStorage";
import jwtDecode from "jwt-decode";
import {AuthProvider} from "react-admin";
import {appApiUrl} from "../App";

type JwtToken = {
    iss: string
    iat: number
    exp: number
    sub: number | string
    name: string
}

export const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        const request = new Request(appApiUrl + '/login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password: password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, refreshToken }) => {
                localStorage.setItem(StorageKey.TOKEN, token);
                localStorage.setItem(StorageKey.REFRESH_TOKEN, refreshToken);

            });
    },
    logout: () => {
        localStorage.removeItem(StorageKey.TOKEN);
        return Promise.resolve();
    },
    checkError: (error) => {
        if (error && (error.status  === 401 || error.status === 403)) {
            localStorage.removeItem(StorageKey.TOKEN);
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return refreshTokens();
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    getIdentity: () => {
        const token = localStorage.getItem(StorageKey.TOKEN);

        if (token === null)
            return Promise.reject("Not authenticated");

        const {name, sub} = jwtDecode<JwtToken>(token);
        return Promise.resolve({
            fullName: name,
            id: sub
        });
    }
}

export const refreshTokens = (): Promise<any> => {
    const token = localStorage.getItem(StorageKey.TOKEN)
    const refreshToken = localStorage.getItem(StorageKey.REFRESH_TOKEN);

    if (token && refreshToken) {
        const { exp } = jwtDecode<JwtToken>(token);

        if (exp > ((new Date().getTime() / 1000) + 30)) {
            return Promise.resolve();
        } else {
            const req = new Request(appApiUrl + '/refresh', {
                method: "POST",
                body: JSON.stringify({ refreshToken: refreshToken})
            });

            return fetch(req)
                .then(res => {
                    if (res.status !== 200) {
                        throw new Error(res.statusText)
                    }
                    return res.json()
                })
                .then(({ token, refreshToken }) => {
                    localStorage.setItem(StorageKey.TOKEN, token)
                    localStorage.setItem(StorageKey.REFRESH_TOKEN, refreshToken)
                    return Promise.resolve();
                }).catch((reason) => {
                    return Promise.reject(`Refresh fetch failed: ${reason}`);
                })
        }
    } else {
        return Promise.reject("Not authorized");
    }
}