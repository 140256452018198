import { Datagrid, DateField, List, TextField } from 'react-admin';
import {useLocation} from "react-router-dom";
import {DrawerDataList} from "../../components/DrawerDataList";
import {Typography} from "@mui/material";

export const EventList = ({rowEdit = null, match = null}) => {
    return (
        <DrawerDataList
            match={match}
            drawer={<Typography>test</Typography>}
        >
            <Datagrid rowClick={rowEdit ?? "edit"}>
                <DateField label={"Date"} source="timestamp" />
                <TextField source="state" />
                <TextField source="source" />
                <TextField source="type" />
            </Datagrid>
        </DrawerDataList>
    )
};