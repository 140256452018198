import {Box, Tabs, Tab, Typography} from "@mui/material";
import {ReactNode, SyntheticEvent, useEffect, useState} from "react";
import {ResourceContextProvider, useAuthenticated, useRedirect} from "react-admin";
import {matchPath, useLocation} from "react-router-dom";

interface TabPanelProps {
    children?: ReactNode;
    index: string;
    value: string;
}
const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vattenfall-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3 }} >
                    {children}
                </Box>
            )}
        </div>
    )
}
export const TabbedResourcePage = ({name, tabs}) => {
    if (!(tabs instanceof Array) ||tabs.length < 1)
        throw new Error("tabs must contain data");
    useAuthenticated();
    const location = useLocation();
    const redirect = useRedirect();
    let match = matchPath(`/${name}/:view/*`, location.pathname);
    // if (!match)
    //     match = matchPath("assets/:view/:action", location.pathname);

    let paths = tabs.map((value) => value[0]);
    const sanitisedMatch = (match) => {
        if (match && paths.includes(match.params.view))
            return match.params.view;

        return paths[0];
    }

    const [tabValue, setTabValue] = useState(sanitisedMatch(match));

    useEffect(() => {
        if (!match || !paths.includes(match.params.view))
            redirect(`/${name}/${paths[0]}`);
    }, [match, redirect]);

    const handleChange = (event: SyntheticEvent, val: string) => {
        setTabValue(val);
        redirect(`/${name}/${val}`);
    };


    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabValue} onChange={handleChange}>
                    {tabs.map((value) => (
                        <Tab value={value[0]} label={value[1]} />
                    ))}
                </Tabs>
            </Box>
            {tabs.map((value, index) => (
                <TabPanel index={value[0]} value={tabValue} >
                    {value[0] !== 'dashboard' && <ResourceContextProvider children={value[2]} value={value[0]} /> }
                    {value[0] === 'dashboard' && value[2]}
                </TabPanel>
            ))}
        </>
    )
};